<template>
  <div class="project-details-notes">
    <h2 class="title">
      {{ $t('label.notes') }}
    </h2>

    <div
      v-if="editMode === false"
      class="preview"
    >
      {{ notes || $t('details.notes') }}
    </div>

    <textarea
      v-else
      class="texts"
      :value="notes"
      :placeholder="$t('details.notes')"
      @change="$emit('change', { notes: $event.target.value })"
    />
  </div>
</template>

<script>
  export default {
    name: 'ProjectDetailsNotes',
    props: {
      editMode: {
        type: Boolean,
        default: false,
      },
      notes: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .project-details-notes {
    > .title {
      @include project-details-block-title;
    }

    > .preview {
      white-space: pre-wrap;
    }

    > .texts {
      font: inherit;
      resize: none;
      width: 100%;
      height: 200px;
    }
  }
</style>
